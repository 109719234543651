<template>
  <div class="peopleBox">
    <div class="input_btn">
      <el-input placeholder="请输入需要查询的人员名称" v-model.trim="nameVal">
        <el-button slot="append" icon="el-icon-search"></el-button>
      </el-input>
      <div>
        <el-button type="primary" @click="searchPeople = true" v-if="
            this.$store.state.current_project.role_id == 5 ||
              this.$store.state.current_project.role_id == 8
          ">添加成员</el-button>
        <el-button type="primary" v-if="
            this.$store.state.current_project.role_id == 5 ||
              this.$store.state.current_project.role_id == 8
          " @click="creatVisibleShow">添加部门</el-button>
        <el-button type="primary" @click="joinProject">加入项目</el-button>
        <el-button type="primary" @click="dialogTableVisibleShow">人员审批</el-button>
      </div>
    </div>
    <div class="listBox">
      <div class="left">
        <el-tree expand-on-click-node :data="listDepartmentMember" node-key="id" :render-content="renderContent" accordion @node-click="nodeclick"></el-tree>
      </div>
      <div class="right">
        <div class="data" v-if="peopleInfo">
          <div class="data_left">
            <div class="first_item">
              <div class="item">
                <div class="title">姓名</div>
                <div class="content">{{ peopleInfo.name }}</div>
              </div>
            </div>
            <div class="item">
              <div class="title">电话</div>
              <div class="content">{{ peopleInfo.mobile }}</div>
            </div>
            <div class="item">
              <div class="title">部门</div>
              <div class="content" v-if="!editVar">
                {{ peopleInfo.department_name }}
              </div>
              <el-select v-model="editPeopleInfo.department_id" placeholder="请选择" v-else size="mini">
                <el-option v-for="item in selectData" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="item">
              <div class="title">角色</div>
              <div v-if="!editVar || peopleInfo.role_id == 5">
                <div class="content" v-if="peopleInfo.role_id == 5">
                  项目所有者
                </div>
                <div class="content" v-else-if="peopleInfo.role_id == 8">
                  项目管理员
                </div>
                <div class="content" v-else-if="peopleInfo.role_id == 6">
                  部门管理员
                </div>
                <div class="content" v-else>普通成员</div>
              </div>
              <el-select v-model="editPeopleInfo.role_id" placeholder="请选择" size="mini" v-if="editVar && peopleInfo.role_id != 5">
                <el-option v-for="item in roldArr" :key="item.value" :label="item.label" :value="item.value" style="width:200px !important">
                </el-option>
              </el-select>
            </div>
            <div class="item">
              <div class="title">工号</div>
              <div class="content" v-if="peopleInfo.employee_number">
                {{ peopleInfo.employee_number }}
              </div>
              <div class="content" v-else>未定义</div>
            </div>
            <div class="item">
              <div class="title">性别</div>
              <div class="content" v-if="peopleInfo.gender == 0">未填写</div>
              <div class="content" v-else-if="peopleInfo.gender == 1">男</div>
              <div class="content" v-else>女</div>
            </div>
            <div class="item">
              <div class="btnBox" style="margin-bottom: 10px">
                <el-button plain size="mini" v-if="editVar" @click="cancelEditPeopleData">取消</el-button>
                <el-button type="primary" plain size="mini" v-if="editVar" @click="clickQueryBtn">修改</el-button>
              </div>
              <span style="display: flex; align-items: center">
                <el-button size="mini" type="text" circle icon="el-icon-edit" style="color: #66b1ff; font-size: 20px" v-if="
                    (this.$store.state.current_project.role_id == 5 ||
                      this.$store.state.current_project.role_id == 8) &&
                      !editVar
                  " @click="editPeopleData"></el-button>
                <el-button size="mini" type="text" circle icon="el-icon-delete" style="color: #f56c6c; font-size: 20px" v-if="
                    (this.$store.state.current_project.role_id == 5 ||
                      this.$store.state.current_project.role_id == 8) &&
                      !editVar
                  " @click="deletePeopleData"></el-button>
              </span>
            </div>
          </div>
          <div class="data_right">
            <div class="item">
              <div class="title">
                <div class="title_name">巡检次数</div>
                <div class="title_content">
                  较前一日
                  <span :class="
                      diffValue.patrolNum >= 0 ? 'color_blue' : 'color_red'
                    ">{{ diffValue.patrolNum }}</span>
                </div>
              </div>
              <div class="num color_blue">
                {{ todayData.complete_task_count }}
              </div>
            </div>
            <div class="border"></div>
            <div class="item">
              <div class="title">
                <div class="title_name">漏检次数</div>
                <div class="title_content">
                  较前一日
                  <span :class="diffValue.omitNum >= 0 ? 'color_blue' : 'color_red'">{{ diffValue.omitNum }}</span>
                </div>
              </div>
              <div class="num color_red">
                {{
                  todayData.prescribed_count - todayData.prescribed_patrol_count
                }}
              </div>
            </div>
            <div class="border"></div>
            <div class="item">
              <div class="title">
                <div class="title_name">任务完成数</div>
                <div class="title_content">
                  较前一日
                  <span :class="
                      diffValue.completetaskcount_num >= 0
                        ? 'color_blue'
                        : 'color_red'
                    ">{{ diffValue.completetaskcount_num }}</span>
                </div>
              </div>
              <div class="num color_blue">
                {{ todayData.complete_task_count }}/{{ todayData.task_count }}
              </div>
            </div>
            <div class="item">
              <div class="title">
                <div class="title_name">已巡点位</div>
                <div class="title_content">
                  较前一日
                  <span :class="
                      diffValue.patrol_count_num >= 0
                        ? 'color_blue'
                        : 'color_red'
                    ">{{ diffValue.patrol_count_num }}</span>
                </div>
              </div>
              <div class="num color_blue">
                {{ todayData.cover_site_count }}/{{todayData.site_count}}

              </div>
            </div>
            <div class="border"></div>
            <div class="item">
              <div class="title">
                <div class="title_name">发现异常</div>
                <div class="title_content">
                  较前一日
                  <span :class="
                      diffValue.problem_count_num >= 0
                        ? 'color_blue'
                        : 'color_red'
                    ">{{ diffValue.problem_count_num }}</span>
                </div>
              </div>
              <div class="num color_red">{{ todayData.problem_count }}</div>
            </div>
            <div class="border"></div>
            <div class="item">
              <div class="title">
                <div class="title_name">处理工单</div>
                <div class="title_content">
                  较前一日
                  <span :class="
                      diffValue.handled_problem_count_num >= 0
                        ? 'color_blue'
                        : 'color_red'
                    ">{{ diffValue.handled_problem_count_num }}</span>
                </div>
              </div>
              <div class="num color_blue">
                {{ todayData.handled_problem_count }}
              </div>
            </div>
          </div>
        </div>
        <div v-else class="checkInfo">请选择需要查看的人员</div>
        <div class="detail" style="margin-top: 8px !important; width: 920px">
          <div class="firstCol">
            <div class="title">
              <i class="el-icon-s-order"></i> 最近巡查记录
            </div>
            <div style="dateClas">
              日期<el-date-picker style="width: 200px; margin-right: 0px;margin-left:30px" v-model="data_stamp" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日" value-format="timestamp"></el-date-picker>
            </div>
          </div>

          <div style="padding: 30px; height: 500px; overflow:scroll" v-infinite-scroll="rollingLoad" infinite-scroll-delay="10000" :infinite-scroll-disabled="true">
            <!-- @node-click="infoNode" -->
            <el-tree :data="patrolHistoryData" node-key="id" :expand-on-click-node="true" @node-expand="infoNode" accordion>
              <span class="custom-tree-node" slot-scope="{ node, data }" v-if="data.index === 1">
                <div class="flex_center_bew" style="width: 100%">
                  <span>{{ node.label }}</span>
                  <div class="flex_center_col">
                    <span v-if="data.patrol_stamp">{{
                      (data.patrol_stamp * 1000)
                        | dateformat("YYYY-MM-DD HH:mm:ss")
                    }}</span>
                    <el-button type="warning" size="mini" v-if="data.skipped == 1" round style="margin-rigth: 20px; margin-left: 30px" plain>跳检</el-button>
                    <el-button size="mini" v-else-if="data.patrol_stamp == 0" round plain style="margin-rigth: 20px; margin-left: 30px">未检</el-button>
                    <el-button type="primary" size="mini" v-else-if="data.situation == 1" round style="margin-rigth: 20px; margin-left: 30px" plain>正常</el-button>
                    <el-button type="danger" size="mini" v-else round style="margin-rigth: 20px; margin-left: 30px" plain>异常</el-button>
                  </div>
                </div>
              </span>
              <span class="custom-tree-node" slot-scope="{ node, data }" v-else-if="data.index === 2">
                <span>{{ node.label }}</span>
                <span v-if="data.patrol_stamp" style="margin-right: -280px">{{
                  (data.patrol_stamp * 1000) | dateformat("YYYY-MM-DD HH:mm")
                }}</span>
                <el-button type="danger" size="mini" v-if="data.situation == 0" round style="margin-rigth: 20px" plain>异常</el-button>
                <el-button type="primary" size="mini" v-else round style="margin-rigth: 20px" plain>正常</el-button>
              </span>
              <span class="custom-tree-node" slot-scope="{ node, data }" v-else-if="data.description || data.remark || data.files || data.value">
                <div>
                  <div v-if="data.value" style="color:#409eff">{{data.value}}</div>
                  <div v-if="data.remark">{{ data.remark }}</div>
                  <div v-if="data.description" style="color:#409eff">
                    <span class="dot"></span> {{ data.description }}
                  </div>
                  <div v-if="data.files">
                    <div class="mediaClass">
                      <div v-for="item in data.files" :key="item.url">
                        <el-image v-if="item.type == 1" style="width: 100px; height: 100px;margin: 0 20px" :src="item.thumbnail_url" :preview-src-list="srcList" accordion></el-image>
                        <span @click="clickVideo(item.url)" style="
                            position: relative;
                            height: 100px;
                            width: 100px;
                            display:inline-block
                          " v-if="item.type == 2">
                          <i class="el-icon-video-play"></i>
                          <video :src="item.url" @click="clickVideoSlef" style="border:1px solid #c1c4cb"></video>
                        </span>
                        <el-image v-if="item.type == 4" style="width: 100px; height: 100px;margin: 0 20px" :src="item.url" :preview-src-list="srcList" accordion></el-image>
                      </div>
                    </div>
                    <div v-for="item in data.files" :key="item.url" style="margin: 20px">
                      <audio :src="item.url" v-if="item.type == 3" controls="controls"></audio>
                    </div>
                  </div>
                </div>
              </span>
            </el-tree>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="修改部门名称" :visible.sync="dialogFormVisible">
      <el-form>
        <el-form-item label="部门旧名称" :label-width="formLabelWidth">
          <el-input v-model="DepartName" disabled></el-input>
        </el-form-item>
        <el-form-item label="部门新名称" :label-width="formLabelWidth">
          <el-input v-model.trim="editDepartName.name" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleFalse">取 消</el-button>
        <el-button type="primary" @click="queryEditDepartName" style="margin-left:30px !important">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 新建部门对话框 -->
    <el-dialog title="新建部门" :visible.sync="creatVisible" close="el-dialog" width="30%">
      <el-form>
        <el-form-item label="部门名称" :label-width="formLabelWidth">
          <el-input v-model.trim="creatDepartName" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="creatVisibleFalse">取 消</el-button>
        <el-button type="primary" @click="queryCreatDepartName" style="margin-left:30px !important">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 开启大图预览功能 -->
    <!-- <el-image-viewer
      v-if="isShow"
      :initial-index="index"
      :on-close="closeViewer"
      :url-list="srcList"
    />-->
    <div class="popContainer" v-show="videoVisible" @click="closeVideoPage">
      <i class="el-icon-circle-close" @click="closeVideoPage"></i>
      <video class="video-js" controls preload="auto" data-setup="{}" ref="myVideo" style="width: 70vw; height: 80vh" :src="videoSrc"></video>
    </div>
    <!-- 弹出一个对话框 用来搜索人员 -->
    <el-dialog title="人员列表" :visible.sync="dialogTableVisible" style="margin: auto" width="800px">
      <el-table :data="memberList" height="550" style="width: 900px">
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column property="id" label="人员" width="300">
          <template slot-scope="scope">
            <div class="flex_center_col">
              <el-avatar icon="el-icon-user-solid" :src="scope.row.user.avatar" v-if="scope.row.user.avatar" @error="errorHandler">
                <img :src="circleUrl"/>
              </el-avatar>
              <el-avatar v-else icon="el-icon-user-solid" @error="errorAvator"></el-avatar>
              <span style="margin-left: 10px">{{ scope.row.joinreason }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="时间" width="200">
          <template slot-scope="scope">
            <span>{{
              scope.row.create_time | dateformat("YYYY-MM-DD HH:mm")
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <div v-if="scope.row.status == 2">
              <el-button type="primary" @click="agreeApoint(scope.row.id)" style="margin-left:0px">同意</el-button>
              <el-button type="danger" @click="rejectApoint(scope.row.id)" style="margin-left:20px !important">拒绝</el-button>
            </div>
            <el-button type="warning" v-if="scope.row.status == 3" @click="quitApoint(scope.row.id)">撤回</el-button>
            <el-button type="info" v-if="scope.row.status == 1">已同意</el-button>
            <el-button type="info" v-if="scope.row.status == 0">已撤回</el-button>
            <el-button type="info" v-if="scope.row.status == 5 || scope.row.status == 4">已拒绝</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination layout="prev, pager, next" :total="memberListTotal" @current-change="currentChange">
      </el-pagination>
    </el-dialog>
    <!-- 邀请加入人员的弹窗 -->
    <el-dialog title="添加人员" :visible.sync="searchPeople" width="30%">
      <el-input placeholder="请输入需要邀请人员的电话号码" v-model.number="keyword" class="input-with-select">
        <el-button slot="append" @click="searchPeopleBtn">搜索</el-button>
      </el-input>

      <div v-if="Object.keys(searchPeopleData).length > 0" class="flex_center_col" style="margin-top: 20px; justify-content: center">
        <el-avatar icon="el-icon-user-solid" :src="searchPeopleData[0].avatar" v-if="searchPeopleData[0].avatar" @error="errorHandler"></el-avatar>
        <el-avatar v-else icon="el-icon-user-solid"></el-avatar>
        <span style="margin-left: 10px">{{ searchPeopleData[0].name }}</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="
            searchPeople = false;
            searchPeopleData = {};
          ">取 消</el-button>
        <el-button type="primary" @click="invitedUser" :disabled="Object.keys(searchPeopleData).length === 0" style="margin-left:30px !important">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 二维码弹出 -->
    <el-dialog :title="
        `扫描二维码，申请加入 ${this.$store.state.current_project.organization.name}`
      " @opened="qsCOdeVisibleOPen" :visible.sync="qsCOdeVisible" width="30%">
      <div id="qrcode" ref="qrcode"></div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="qsCOdeVisible = false" style="margin-left:30px !important">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { organization, statistic, patrol, user, envUrl } from '../../utils/api';
import { isTelAvailable } from '../../utils/verification';
import QRCode from 'qrcodejs2';
import qs from 'qs';
export default {
  name:"personnel",
  data() {
    return {
      //  获取部门信息
      listDepartmentMember: [],
      // 用于存储信息列表
      selectData: [],
      // 原部门名称
      DepartName: '',
      // 修改部门名称
      editDepartName: {
        department_id: '',
        name: ''
      },
      // 用于控制显示dialog对话框
      dialogFormVisible: false,
      // 用于控制显示新建部门对话框
      creatVisible: false,
      // 新创建部门的名称
      creatDepartName: '',
      formLabelWidth: '120px',
      // 右侧面板展示个人信息
      peopleInfo: '',
      //获取日报所需的时间参数
      dayReport: {
        date_stamp: '',
        user_id: ''
      },
      data_stamp: '',
      diffValue: '',
      //判断是否给予加载
      rolling: true,
      // 获取巡查记录
      patrolHistory: {
        date_stamp: '',
        scope: 0,
        page: 0,
        page_size: 10,
        type: 0,
        user_id: 0
      },
      patrolHistoryData: [],
      srcList: [],
      isShow: false,
      // 视频弹窗
      videoVisible: false,
      // 视频地址
      videoSrc: '',
      // 监视左上角input框
      nameVal: '',
      todayData: {},
      yesterdayData: {},
      // 更新其管理角色以及部门
      editPeopleInfo: {
        department_id: '',
        role_id: '',
        user_id: ''
      },
      roldArr: [
        // { label: '项目所有者', value: 5 },
        { label: '项目管理员', value: 8 },
        { label: '部门管理者', value: 6 },
        { label: '普通成员', value: 7 }
      ],
      editVar: false,
      JoinListPar: {
        page: 1,
        page_size: 10
      },
      memberList: [],
      memberListTotal: '',
      dialogTableVisible: false,
      searchPeople: false,
      keyword: '', // 搜索的手机号
      searchPeopleData: {},
      qsCOdeVisible: false,
      circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
    };
  },

  components: {},
  async updated(){
    await this.$nextTick()
    this.handleImgErr()
  },

  computed: {},
  created() {
    this.data_stamp = new Date(new Date().toLocaleDateString()).getTime();
    this.getPeopleList();
  },
  // mounted() {
  //   this.qrCode(
  //     `https://bat.i-patrol.cn/navigate/?operate=invite&=organization_id=${this.$store.state.organization_id}`
  //   );
  // },
  methods: {
    // 获取部门成员列表
    async getPeopleList() {
      const res = await this.$http.get(organization.listDepartmentMember);
      let selectData = [];
      res.data.forEach(item => {
        let obj = {};
        obj.label = item.name;
        obj.value = item.id;
        selectData.push(obj);
        item.label = item.name;
        item.children = item.members;
        item.children.forEach(citem => {
          citem.patrol_stamp = item.patrol_stamp;
          citem.id = citem.user_id;
          citem.label = citem.name;
          citem.department_name = item.label;
        });
      });
      this.listDepartmentMember = res.data;
      let cloneListDepartmentMember = JSON.parse(
        JSON.stringify(this.listDepartmentMember)
      );
      this.cloneListDepartmentMember = cloneListDepartmentMember;
      this.selectData = selectData;
    },
    append(data) {
      // e.stopPropagation()
      window.event ? (window.event.cancelBubble = true) : e.stopPropagation();
      if (+data.id === 0) {
        return this.$message.error('该分组不可修改名称');
      }
      this.DepartName = data.label;
      this.editDepartName.department_id = data.id;
      this.dialogFormVisible = true;
    },
    // 删除部门
    remove(node, data) {
      window.event ? (window.event.cancelBubble = true) : e.stopPropagation();
      let that = this;
      if (+data.id === 0) {
        return this.$message.error('该分组不可删除');
      }
      this.DepartName = data.label;
      this.$confirm('此操作将永久删除该部门, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        confirmButtonClass: 'queryBtn',
        type: 'warning'
      })
        .then(async () => {
          const res = await that.$http.get(
            organization.deleteDepartment + '?department_id=' + data.id
          );
          if (res.code === 0) {
            // this.listDepartmentMember.forEach((item, index, arr) => {
            //   if (item.id == data.id) {
            //     arr.splice(index, 1);
            //   }
            // });
            await this.getPeopleList();
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
    },
    // 渲染左侧树状数据
    renderContent(h, { node, data, store }) {
      if (data.children) {
        if (
          this.$store.state.current_project.role_id == 5 ||
          this.$store.state.current_project.role_id == 8
        ) {
          return (
            <div class="custom-tree-node">
              <div class="custom-tree-node-title">
                {node.label} ({data.children.length})
              </div>
              <div>
                <span style="display:flex;align-items: center;">
                  <el-button
                    size="mini"
                    type="text"
                    circle
                    icon="el-icon-edit"
                    style="color:#66b1ff;font-size:20px"
                    on-click={() => this.append(data)}
                  ></el-button>
                  <el-button
                    size="mini"
                    type="text"
                    circle
                    icon="el-icon-delete"
                    style="color:#f56c6c;font-size:20px"
                    on-click={() => this.remove(node, data)}
                  ></el-button>
                </span>
              </div>
            </div>
          );
        } else
          return (
            <div class="custom-tree-node">
              <div class="custom-tree-node-title">
                {node.label} ({data.children.length})
              </div>
            </div>
          );
      } else {
        if (data.avatar) {
          return (
            <div class="custom-tree-node">
              <div class="custom-tree-node-title">
                <el-avatar src={data.avatar}></el-avatar>{' '}
                <span>{node.label}</span>
              </div>
            </div>
          );
        } else
          return (
            <div class="custom-tree-node active-node">
              <div class="custom-tree-node-title">
                <el-avatar icon="el-icon-user-solid"></el-avatar>{' '}
                <span>{node.label}</span>
              </div>
            </div>
          );
      }
    },
    dialogFormVisibleFalse() {
      this.editDepartName.name = '';
      this.dialogFormVisible = false;
    },
    // 确定发送添加部门请求
    async queryEditDepartName() {
      //  先判断部门名称是否为空
      if (!this.editDepartName.name) {
        return this.$message.error('请填写新部门名称');
      }
      const res = await this.$http.post(
        organization.editDepartment,
        this.editDepartName
      );
      if (res.code === 0) {
        // 进行优化代码 更新并修改原始数据
        this.listDepartmentMember.forEach(item => {
          if (item.name == this.DepartName) {
            item.label = this.editDepartName.name;
            return false;
          }
        });
        this.dialogFormVisibleFalse();
      } else {
        this.$message.error('名称已存在');
      }
    },
    //  创建部门隐藏 并清空相对应的部门名称
    creatVisibleFalse() {
      this.creatVisible = false;
      this.creatDepartName = '';
    },
    // 显示添加部门对话框
    creatVisibleShow() {
      this.creatVisible = true;
    },
    // 点击创建新部门
    async queryCreatDepartName() {
      const res = await this.$http.post(organization.createDepartment, {
        name: this.creatDepartName
      });
      if (res.code === 0) {
        // 更新信息列表
        this.getPeopleList();
        // 情况并关闭弹窗
        this.creatVisibleFalse();
      }
    },
    // 点击事件
    nodeclick(data, node, store) {
      if (this.editVar) {
        return false;
      }
      if (!data.members) {
        this.peopleInfo = data;
        this.editPeopleInfo.department_id = data.department_id;
        this.editPeopleInfo.role_id = data.role_id;
        this.editPeopleInfo.user_id = data.user_id;
      }
    },
    // 获取日报
    async getDayReport() {
      let par = qs.stringify(this.dayReport);
      const res = await this.$http.get(statistic.memberDailyReport + '?' + par);
      let todayData = res.data.today;
      let yesterdayData = res.data.yesterday;
      this.todayData = todayData;
      this.yesterdayData = yesterdayData;
      function addPlus(today, yesterday) {
        let result = today - yesterday;
        if (result >= 0) {
          result = '+' + result;
        }
        return result;
      }
      let diffValue = {
        //巡检次数
        patrolNum: addPlus(todayData.patrol_count, yesterdayData.patrol_count),
        // 漏检次数
        omitNum: addPlus(
          todayData.prescribed_count - todayData.prescribed_patrol_count,
          yesterdayData.prescribed_count - yesterdayData.prescribed_patrol_count
        ),
        //任务完成数
        completetaskcount_num: addPlus(
          todayData.complete_task_count,
          yesterdayData.complete_task_count
        ),
        //已巡点位
        patrol_count_num: addPlus(
          todayData.prescribed_patrol_count,
          yesterdayData.prescribed_patrol_count
        ),
        //发现异常
        problem_count_num: addPlus(
          todayData.problem_count,
          yesterdayData.problem_count
        ),
        //处理工单
        handled_problem_count_num: addPlus(
          todayData.handled_problem_count,
          yesterdayData.handled_problem_count
        )
      };
      this.diffValue = diffValue;
    },
    // 滚动加载数据
    async rollingLoad() {
      let that = this;
      if (!this.rolling || this.patrolHistory.user_id == 0) {
        return false;
      }
      setTimeout(async function() {
        if (!that.rolling) {
          return false;
        }
        if (!that.patrolHistory.date_stamp) {
          return (that.rolling = false);
        }
        that.patrolHistory.page++;
        let par = '?' + qs.stringify(that.patrolHistory);
        const res = await that.$http.get(patrol.patrolHistory + par);
        if (!res.data) {
          return false;
        }
        res.data.forEach(item => {
          // 第一级
          item.label = item.site_name;
          item.children = item.inspection_logs;
          item.index = 1;
          item.children.forEach(citem => {
            // 第二级
            citem.index = 2;
            citem.label = citem.inspection_name;
            citem.children = citem.option_logs.filter(
              fitem => fitem.description && fitem.selected == 1
            );
            if (citem.signature) {
              citem.files.push({ type: 4, url: citem.signature });
            }
            if (citem.remark) {
              citem.children.push({ remark: citem.remark });
            }
            if (citem.inspection.type == 4) {
              citem.children.unshift({ value: citem.value });
            }
            if (citem.files.length > 0) {
              citem.children.push({ files: citem.files }); /*  */
            }
          });
        });
        // 应该用total的 会准确一点
        that.patrolHistoryData = [...that.patrolHistoryData, ...res.data];
        if (that.patrolHistoryData.length == res.total) {
          that.rolling = false;
        }
      }, 1);
    },
    infoNode(data, node, self) {
      if (+node.level === 2) {
        let srcList = [];
        if (
          data.children[data.children.length - 1].files &&
          data.children[data.children.length - 1].files.length > 0
        ) {
          data.children[data.children.length - 1].files.forEach(item => {
            if (item.type === 1 || item.type === 4) {
              srcList.push(item.url);
            }
          });
          this.srcList = srcList;
        }
      }
    },
    clickVideoSlef() {
      window.event ? (window.event.returnValue = false) : e.preventDefault();
    },
    clickVideo(url) {
      window.event ? (window.event.returnValue = false) : e.preventDefault();
      this.$refs.myVideo.src = url;
      this.$refs.myVideo.play();
      this.videoVisible = true;
    },
    closeVideoPage() {
      this.$refs.myVideo.pause();
      this.videoVisible = false;
    },
    // 点击修改按钮修改人员信息
    editPeopleData() {
      this.editVar = true;
    },
    // 点击取消按钮取消编辑人员信息并恢复数据
    cancelEditPeopleData() {
      (this.editVar = false),
        (this.editPeopleInfo.department_id = this.peopleInfo.department_id);
      this.editPeopleInfo.role_id = this.peopleInfo.role_id;
    },
    // 点击修改按钮确定发送请求修改数据
    async clickQueryBtn() {
      if (this.editPeopleInfo.department_id != this.peopleInfo.department_id) {
        // 进行修改部门操作
        const res1 = await this.$http.post(
          organization.memberDepartmentManage,
          {
            user_ids: [this.editPeopleInfo.user_id],
            department_id: this.editPeopleInfo.department_id
          }
        );
        if (res1.code == 0) {
          this.peopleInfo.department_id = this.editPeopleInfo.department_id;
          this.selectData.forEach(item => {
            if (item.value == this.editPeopleInfo.department_id) {
              this.peopleInfo.department_name = item.label;
            }
          });
          this.$message.success('修改成功')
          this.getPeopleList();
        } else {
          // this.$message.error('无修改人员部门操作权限');
          this.editPeopleInfo.department_id = this.peopleInfo.department_id;
        }
      }
      if (this.editPeopleInfo.role_id != this.peopleInfo.role_id) {
        const res2 = await this.$http.post(organization.memberRoleManage, {
          user_id: this.editPeopleInfo.user_id,
          role_id: this.editPeopleInfo.role_id
        });
        if (res2.code == 0) {
          this.$message.success('修改成功')
          this.peopleInfo.role_id = this.editPeopleInfo.role_id;
        } else {
          // this.$message.error('权限不足');
          this.editPeopleInfo.role_id = this.peopleInfo.role_id;
        }
      }
      this.$forceUpdate();
      this.editVar = false;
    },
    // 獲取人員審批列表
    async getListJoin() {
      const res = await this.$http.get(
        organization.listMemberJoin + '?' + qs.stringify(this.JoinListPar)
      );
      res.data.forEach(item => {
        if (item.user.name.length > 10) {
          item.user.name = item.user.name.substring(0, 9) + '...';
        }
        if (item.invite_user) {
          if (typeof item.invite_user === String) {
            item.invite_user =
              item.invite_user.length > 10
                ? item.invite_user.substring(0, 9) + '...'
                : item.invite_user;
          } else {
            item.invite_user =
              item.invite_user.name.length > 10
                ? item.invite_user.name.substring(0, 9) + '...'
                : item.invite_user.name;
          }
          item.joinreason = item.invite_user + '邀请' + item.user.name + '加入';
        } else {
          item.joinreason = item.user.name + '申请加入项目';
        }
        item.create_time = item.create_time * 1000;
      });
      this.memberList = res.data;
      this.memberListTotal = res.total;
      this.$forceUpdate();
    },
    // 删除部门成员
    async deleteMember() {
      const res = await this.$http.get(
        organization.deleteMember + '?' + this.peopleInfo.user_id
      );
      if (res === 0) {
        this.getPeopleList();
        this.peopleInfo = {};
      }
    },
    // 页码改变时操作
    async currentChange(page) {
      (this.JoinListPar.page = page), await this.getListJoin();
      this.$forceUpdate();
    },
    // 点击搜索按钮用户
    async searchPeopleBtn() {
      this.searchPeopleData = {};
      this.$forceUpdate();
      if (!isTelAvailable(this.keyword)) {
        return this.$message.error('请输入正确的手机号');
      }
      const res = await this.$http.get(
        organization.searchUser + '?keyword=' + this.keyword
      );
      if (res.code === 0 && res.data.length > 0) {
        this.searchPeopleData = res.data;
      } else {
        this.$message.error('人物不存在');
      }
    },
    // 邀请用户加入群聊
    async invitedUser() {
      const res = await this.$http.post(organization.inviteToJoin, {
        user_id: this.searchPeopleData[0].id
      });
      if (res.code === 0) {
        (this.keyword = ''), (this.searchPeopleData = {});
        this.$message.success('操作成功')
        this.searchPeople = false;
      } else {
        this.$message.error(res.message);
        (this.keyword = ''), (this.searchPeopleData = {});
      }
    },
    // 加入项目
    joinProject() {
      // if(!this.$refs.qrcode.innerHTML){
      //   this.qrCode(`https://bat.i-patrol.cn/navigate/?operate=invite&=organization_id=${this.$store.state.organization_id}`)
      // }
      this.qsCOdeVisible = true;
    },
    //打开二维码对话框的回调
    qsCOdeVisibleOPen() {
      if (!this.$refs.qrcode.innerHTML) {
        this.qrCode(
          // `https://bat.i-patrol.cn/navigate/?operate=invite&organization_id=${this.$store.state.current_project.organization_id}` //正式版
          `${envUrl}navigate/?operate=invite&organization_id=${this.$store.state.current_project.organization_id}` //测试版
        );
      }
    },
    qrCode(url) {
      let qrcode = new QRCode('qrcode', {
        width: 150, //图像宽度
        height: 150, //图像高度
        colorDark: '#000000', //前景色
        colorLight: '#ffffff', //背景色
        typeNumber: 4,
        correctLevel: QRCode.CorrectLevel.H //容错级别 容错级别有：（1）QRCode.CorrectLevel.L （2）QRCode.CorrectLevel.M （3）QRCode.CorrectLevel.Q （4）QRCode.CorrectLevel.H
      });
      qrcode.makeCode(url); //生成另一个新的二维码
    },
    // 同意用户加入项目
    async agreeApoint(id, isAgree = 1) {
      this.$confirm('此操作将同意用户加入项目, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const res = await this.$http.post(organization.approveApply, {
            mjid: id,
            pass: isAgree
          });
          if (res.code === 0) {
            this.$message.success('已同意');
            this.getListJoin();
          } else {
            this.$message.error('操作失败');
          }
        })
        .catch(() => {});
    },
    async rejectApoint(id, isAgree = 0) {
      this.$confirm('此操作将拒绝用户加入项目, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const res = await this.$http.post(organization.approveApply, {
            mjid: id,
            pass: isAgree
          });
          if (res.code === 0) {
            this.$message.success('已拒绝');
            this.getListJoin();
          } else {
            this.$message.error('操作失败');
          }
        })
        .catch(() => {});
    },
    async quitApoint(id) {
      this.$confirm('此操作将撤回邀请用户加入项目, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const res = await this.$http.post(organization.revokeInvite, {
            mjid: id
          });
          if (res.code === 0) {
            this.$message.success('已撤回');
            this.getListJoin();
          } else {
            this.$message.error('操作失败');
          }
        })
        .catch(() => {});
    },
    dialogTableVisibleShow() {
      this.getListJoin();
      this.dialogTableVisible = true;
    },
    // 删除个人信息
    async deletePeopleData() {
      this.$confirm('此操作将删除该部门人员, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const res = await this.$http.get(
            `${organization.deleteMember}?user_id=${this.editPeopleInfo.user_id}`
          );
          if (res.code === 0) {
            this.$message.success('删除成功');
            this.peopleInfo = '';
            this.getPeopleList();
          } else {
            this.$message.success('权限不足');
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
    },
    handleImgErr() {
      let that = this
      document.addEventListener(
        'error',
        async function(e) {
          var elem = e.target;
          if (elem.tagName.toLowerCase() == 'img') {
            if(elem.parentNode.children[2]){
              return false
            }
            var img= document.createElement("img");
            img.src = "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
            elem.parentNode.appendChild(img)
            // elem.src = 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png';
            await that.$forceUpdate()
          }
        },
        true
      );
    },
  },
  watch: {
    // 监视时间戳
    data_stamp(value) {
      this.dayReport.date_stamp = value / 1000;
      this.patrolHistory.date_stamp = value / 1000;
      this.patrolHistoryData = [];
      this.patrolHistory.page = 0;
      if (!this.peopleInfo.user_id) {
        return false;
      }
      this.rolling = true;
      // if(!this.date_stamp){
      //   return false
      // }
      this.getDayReport();
      this.rollingLoad();
    },
    // 监视id变化 并且发送数据请求更新视图
    'peopleInfo.user_id'(newvalue, oldValue) {
      if (!newvalue || newvalue == 0 || !this.data_stamp) {
        return false;
      }
      this.dayReport.user_id = newvalue;
      this.patrolHistory.user_id = newvalue;
      this.patrolHistoryData = [];
      this.patrolHistory.page = 0;
      this.rolling = true;
      this.rollingLoad();
      this.getDayReport();
    },
    // 监视数据 并使用正则表达式 过滤数据
    nameVal(value) {
      var reg = new RegExp(value, 'g'); // 'g'代表全局
      let arr = JSON.parse(JSON.stringify(this.cloneListDepartmentMember));
      arr.forEach(item => {
        item.children = item.children.filter(citem => {
          return reg.test(citem.name);
        });
      });
      this.listDepartmentMember = arr;
    },
    errorHandler() {
        return true
      }
  }
};
</script>
<style lang="scss" scoped>
.peopleBox {
  width: 1200px;
  /deep/.custom-tree-node {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 50px !important;
    .custom-tree-node-title {
      text-align: left;
      line-height: 100px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 40px;
    }
  }
  /deep/ .el-tree-node__content {
    height: 50px;
  }
  /deep/ .input_btn {
    display: flex;
    justify-content: space-between;
  }
  /deep/ .el-input {
    width: 300px;
  }
  /deep/ .listBox {
    display: flex;
    margin-top: 8px;
  }
  .left {
    width: 300px;
    margin-right: 7px;
    height: 785px;
    border-radius: 5px;
    overflow: auto;
    background-color: #fff;
  }
  .right {
    // background-color: #fff;
    flex: 1;
    // overflow-x: scroll;
    border-radius: 5px;
    .checkInfo {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 920px;
      height: 218px;
      color: #909399;
      background-color: #ffffff;
      box-sizing: border-box;
      border-radius: 5px;
    }
    .data {
      width: 920px;
      display: flex;
      background-color: #ffffff;
      padding: 8px;
      box-sizing: border-box;
      border-radius: 5px;
      .data_left {
        width: 250px;
        background: #ffffff;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        padding-left: 16px;
        height: 200px;
        .first_item {
          display: flex;
          justify-content: space-between;
          height: 28px;
        }
        .item {
          display: flex;
          height: 28px;
          align-items: center;
          .btnBox {
            display: flex;
            justify-content: space-around;
            width: 250px;
            // margin-top: 20px;
          }
          .title {
            margin-right: 20px;
            white-space: nowrap;
          }
          .content {
            width: 120px;
            overflow: hidden;
            text-overflow: ellipsis;
            // white-space: nowrap;
          }
        }
      }
      .detail {
        background-color: #ffffff;
        margin-top: 8px !important;
        width: 865px;
        border-radius: 5px;
      }
      .data_right {
        flex: 0 0 645px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        height: 200px;
        background: #ffffff;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        margin-left: 8px;
        .item {
          box-sizing: border-box;
          width: 30%;
          //  background-color: salmon;
          .title {
            display: flex;
            justify-content: space-around;
            align-items: flex-start;
            .title_name {
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 700;
              color: #333333;
              line-height: 20px;
            }
            .title_content {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              line-height: 20px;
            }
            .color_red {
              color: #f04749;
            }
            .color_blue {
              color: #0486fe;
            }
          }
          .num {
            margin-top: 30px;
            text-align: left;
            font-size: 20px;
            font-weight: 700;
            margin-left: 12px;
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            color: #333333;
            line-height: 28px;
            margin-top: 0px;
          }
          .color_red {
            color: #f04749;
          }
          .color_blue {
            color: #0486fe;
          }
        }
        .border {
          width: 1px;
          border-right: 1px dashed #f4f4f4;
          height: 34px;
          // background-color: #ccc;
          // height: 80px;
        }
      }
    }
    .detail {
      background-color: #ffffff;
      border-radius: 5px;
      .firstCol {
        display: flex;
        box-sizing: border-box;
        width: 100%;
        padding: 10px;
        justify-content: space-between;
        align-items: center;
        .title {
          font-size: $fontSizeMap-20;
          font-weight: 600;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 700;
          color: #333333;
          line-height: 22px;
          margin-left: 12px;
        }
      }
    }
    .dateClas {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
    }
    .mediaClass {
      display: flex;
      align-items: center;
      height: 100px;
      margin-top: 50px;
      .el-icon-video-play {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -25px;
        margin-left: -25px;
        font-size: 50px;
        color: #000000;
      }
    }
  }
}
.item /deep/ .el-input {
  width: 180px;
}
#qrcode {
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-button--mini,
.el-button--small {
  margin-right: 20px;
}
.peopleBox /deep/ .el-dialog {
  width: 800px;
}
.peopleBox /deep/ .el-button + .el-button {
  margin-left: -10px !important;
}
.peopleBox /deep/.el-avatar {
  margin-right: 10px;
}
.peopleBox /deep/.el-button.el-button--primary {
  margin-left: 30px;
}
.peopleBox
  /deep/
  .el-button.el-button--primary
  + .el-button.el-button--primary {
  margin-left: 30px !important;
}
.peopleBox /deep/ .el-button.el-button--danger.el-button--mini.is-round,
.peopleBox /deep/ .el-button.el-button--primary.el-button--mini.is-round {
  margin-right: 20px;
}
.peopleBox /deep/ .el-tree-node__content {
  min-height: 26px !important;
  height: auto;
}
.peopleBox /deep/ .custom-tree-node .custom-tree-node-title {
  line-height: 0 !important;
}
video {
  width: 100px;
  height: 100px;
}
.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  // color: grey;
  margin-right: 10px;
  background-color: rgb(64, 158, 255);
  border-radius: 50%;
}
div.popContainer {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  .el-icon-circle-close {
    position: absolute;
    right: 15%;
    top: 10%;
    font-size: 50px;
    color: slategray;
  }
}
.el-input + .el-input {
  margin-left: 20px;
}
</style>
